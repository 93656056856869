import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function LoginLoader() {
  const spinnerStyle = {
    fontSize: "40px",
    marginBottom: "10px",
  };

  const loadingMessageStyle = {
    textAlign: "center",
  };

  const spinnerContainerStyle = {
    position: "fixed",
    zIndex: "9999",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255,255,255,0.49)",
    // backgroundColor: "rgba(204,239,225,0.8)", // Darker green background color with transparency
    color: "black",
    fontSize: "16px",
  };

  return (
    <div style={spinnerContainerStyle}>
      <div style={spinnerStyle}>
        <FontAwesomeIcon icon={faSpinner} spin />
      </div>
      <div style={loadingMessageStyle}>Please wait...</div>
    </div>
  );
}

export default LoginLoader;
