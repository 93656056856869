import React, { useState } from "react";
import { connect } from "react-redux";
import { setHomePageContents } from "../../../Actions/Actions";
import mainLogo from "../../../Resources/logo.svg";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import { Link } from "react-router-dom";
import { encryptData, serverLink } from "../../../Resources/Url";
import axios from "axios";
import { toast } from "react-toastify";

const Footer = (props) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return (
    <ErrorBoundary>
      <footer>
        <div className="container-fluid">
          <div className="row" style={{ marginLeft: "15px" }}>
            <div className="col-md-3">Enyimba Tv & Radio Ltd.</div>

            <div className="col-md-9">
              <ul className="clearfix">
                <li>
                  <Link to="/" className="animated_link">
                    Enyimba FM
                  </Link>
                </li>
                <li>
                  <Link to="/" className="animated_link">
                    Legend FM
                  </Link>
                </li>
                <li>
                  <Link to="/" className="animated_link">
                    Rose FM
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnHomePageData: (p) => {
      dispatch(setHomePageContents(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
